// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-archive-index-js": () => import("./../../../src/templates/Archive/index.js" /* webpackChunkName: "component---src-templates-archive-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/Category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/Project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */)
}

